import React, { useState } from 'react';
import { API } from '../settings';
import ConfirmLn from './common/ConfirmLn';
import { Delete16Regular as DeleteIcon } from '@fluentui/react-icons';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const DeleteButton = ({ url, schemaName }) => {
  const history = useHistory();

  const uppercaseSchemaName = schemaName.substr(0, 1).toUpperCase() + schemaName.slice(1);

  const [isDeleting, setIsDeleting] = useState(false);

  const deleteEntry = async () => {
    try {
      setIsDeleting(true);

      await API({
        method: 'DELETE',
        url
      });

      history.goBack();
      toast.success(`${uppercaseSchemaName} deleted!`);
    } catch (err) {
      toast.error(err?.response ? err.response.data :
        `There was an error deleting this ${schemaName}`);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <ConfirmLn
      renderBtn={
        <button
          disabled={isDeleting}
          className="ln-btn ln-icon-btn negative default small"
        >
          <DeleteIcon className="ln-icon" />
        </button>
      }
      danger
      position="left"
      title={`Are you sure you want to delete this ${schemaName}? This action cannot be undone.`}
      onOk={deleteEntry}
      okText="Yes, Delete"
      noText="No, Cancel"
    />
  );
};

export default DeleteButton;
