import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import CounselorLoggedInLayout from '../components/layouts/CounselorLoggedInLayout';
import CounselorDashboard from '../components/counselor/AdminDashboard';
import UploadStudents from '../components/counselor/UploadStudents';
import Students from '../components/counselor/Students';
import Student from '../components/student-view';
import Employers from '../components/counselor/Employers/Employers';
import CounselorSettings from '../components/counselor/Settings/CounselorSettings';
import CounselorProfile from '../components/counselor/Settings/sections/CounselorProfile';
import CounselorPassword from '../components/counselor/Settings/sections/CounselorPassword';
// import CounselorSettingsLayout from '../components/layouts/CounselorSettingsLayout';
// import CounselorSettings from '../components/Counselor/CounselorSettings';
// import CounselorProfile from '../components/Counselor/settings/CounselorProfile';
// import CounselorBusiness from '../components/Counselor/settings/CounselorBusiness';
// import CounselorPassword from '../components/Counselor/settings/CounselorPassword';
// import CounselorDocuments from '../components/Counselor/settings/CounselorDocuments';
// import CounselorOpportunities from '../components/Counselor/CounselorOpportunities'
// import CounselorOpportunity from '../components/Counselor/CounselorOpportunity'

function CounselorRoutes(props) {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={(props) => (
          <CounselorLoggedInLayout {...props}>
            <CounselorDashboard {...props} />
          </CounselorLoggedInLayout>
        )}
      />
      <Route
        path={`${path}/dashboard`}
        render={(props) => (
          <CounselorLoggedInLayout {...props}>
            <CounselorDashboard {...props} />
          </CounselorLoggedInLayout>
        )}
      />
      <Route
        path={`${path}/upload-students`}
        render={(props) => (
          <CounselorLoggedInLayout {...props}>
            <UploadStudents {...props} />
          </CounselorLoggedInLayout>
        )}
      />
      <Route
        path={`${path}/students/:uuid`}
        render={(props) => (
          <CounselorLoggedInLayout {...props}>
            <Student {...props} />
          </CounselorLoggedInLayout>
        )}
      />
      <Route
        path={`${path}/students`}
        render={(props) => (
          <CounselorLoggedInLayout {...props}>
            <Students {...props} />
          </CounselorLoggedInLayout>
        )}
      />
      <Route
        path={`${path}/employers`}
        render={(props) => (
          <CounselorLoggedInLayout {...props}>
            <Employers {...props} />
          </CounselorLoggedInLayout>
        )}
      />
      <Route
        path={`${path}/upload-students`}
        render={(props) => (
          <CounselorLoggedInLayout {...props}>
            <UploadStudents {...props} />
          </CounselorLoggedInLayout>
        )}
      />
      <Route
        path={`${path}/settings`}
        exact
        render={(props) => (
          <CounselorLoggedInLayout {...props}>
            <CounselorSettings {...props}>
              <CounselorProfile {...props} />
            </CounselorSettings>
          </CounselorLoggedInLayout>
        )}
      />
      <Route
        path={`${path}/settings/password`}
        render={(props) => (
          <CounselorLoggedInLayout {...props}>
            <CounselorSettings {...props}>
              <CounselorPassword {...props} />
            </CounselorSettings>
          </CounselorLoggedInLayout>
        )}
      />
      {/* <Route
        path={`${path}/settings/my-business`}
        render={(props) => (
          <EmployerLoggedInLayout {...props}>
            <EmployerSettings {...props}>
              <EmployerBusiness {...props} />
            </EmployerSettings>
          </EmployerLoggedInLayout>
        )}
      />
      <Route
        path={`${path}/settings/documents`}
        render={(props) => (
          <EmployerLoggedInLayout {...props}>
            <EmployerSettings {...props}>
              <EmployerDocuments {...props} />
            </EmployerSettings>
          </EmployerLoggedInLayout>
        )}
      /> */}

      {/* <Route
        exact
        path={`${path}/opportunities`}
        render={(props) => (
          <CounselorLoggedInLayout {...props}>
            <CounselorOpportunities {...props} />
          </CounselorLoggedInLayout>
        )}
      />
      <Route
        path={`${path}/opportunities/:opportunityId`}
        render={(props) => (
          <CounselorLoggedInLayout {...props}>
            <CounselorOpportunity {...props} />
          </CounselorLoggedInLayout>
        )}
      />
      <Route
        path={`${path}/settings`}
        exact
        render={(props) => (
          <CounselorLoggedInLayout {...props}>
            <CounselorSettings {...props}>
              <CounselorProfile {...props} />
            </CounselorSettings>
          </CounselorLoggedInLayout>
        )}
      />
      <Route
        path={`${path}/settings/my-business`}
        render={(props) => (
          <CounselorLoggedInLayout {...props}>
            <CounselorSettings {...props}>
              <CounselorBusiness {...props} />
            </CounselorSettings>
          </CounselorLoggedInLayout>
        )}
      />
      <Route
        path={`${path}/settings/password`}
        render={(props) => (
          <CounselorLoggedInLayout {...props}>
            <CounselorSettings {...props}>
              <CounselorPassword {...props} />
            </CounselorSettings>
          </CounselorLoggedInLayout>
        )}
      />
      <Route
        path={`${path}/settings/documents`}
        render={(props) => (
          <CounselorLoggedInLayout {...props}>
            <CounselorSettings {...props}>
              <CounselorDocuments {...props} />
            </CounselorSettings>
          </CounselorLoggedInLayout>
        )}
      />
      <Route
        path={`${path}/settings`}
        render={(props) => (
          <CounselorLoggedInLayout {...props}>
            <CounselorSettings {...props} />
          </CounselorLoggedInLayout>
        )}
      /> */}
    </Switch>
  );
}

const mapStateToProps = (state) => ({ user: state.user });
export default connect(mapStateToProps)(CounselorRoutes);
