import React, { useState } from 'react';
import { ChevronRight28Regular as ChevronRightIcon } from '@fluentui/react-icons';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import InterestedInBtn from '../StudentInterestedIn/InterestedInBtn';

const Wrapper = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin: 0 0 20px 0;
`;
const ItemTitle = styled.h5`
  font-size: 16px;
  margin-bottom: 2px;
`;
const MetaData = styled.p`
  margin: 0;
  font-size: 12px;
  color: #afafaf;
`;
const ChevronRight = styled(ChevronRightIcon)`
  color: #d4d4d4;
`;

const DeleteBtn = styled.button`
  color: #ff7272;
`;

const CompanyLogo = styled.img`
  height: 30px;
  width: 30px;
  margin: 0 5px;
`;

const ItemDataWrapper = styled.div`
  justify-self: flex-start;
  padding-left: 10px;
  width: 100%;
`;

export default function OpportunityItem(props) {
  const {
    imgSrc,
    imgAlt,
    title,
    companyName,
    location,
    to,
    isBookmarked,
    opportunityId,
    onInterestRevoke,
  } = props;
  const [active, setState] = useState(false);

  return (
    <Wrapper to={to}>
      {/*<CompanyLogo src={imgSrc} alt={imgAlt}/>*/}
      <ItemDataWrapper>
        <ItemTitle>{title}</ItemTitle>
        <MetaData>
          {companyName} • {location}
        </MetaData>
      </ItemDataWrapper>
      {/*<DeleteBtn className='ln-btn ln-icon-btn'>*/}
      {/*  <DeleteIcon className="ln-icon"/>*/}
      {/*</DeleteBtn>*/}
      <InterestedInBtn
        iconBtn={true}
        onChange={onInterestRevoke}
        isInterestedIn={true}
        opportunityId={opportunityId}
      />
    </Wrapper>
  );
}
