import React from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import Avatar from 'react-avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import StudentProfileAssessmentSection from './StudentProfileAssessmentSection';
import { API } from '../settings';
import { getStudentProfile } from '../redux/actions/student';

const PageWrapper = styled.div`
  min-height: 100vh;
  min-height: -webkit-fill-available;
  padding: 25px;
  position: relative;
  width: 100%;
`;

const ProfileHeader = styled.div`
  text-align: center;
  position: relative;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15rem;
`;

const LoadingSpinner = styled(Spinner)`
  height: 50px;
  width: 50px;
`;

const SettingsLink = styled(Link)`
  color: #797878;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 24px;

  &:hover {
    color: #797878;
  }
`;

const ProfileName = styled.h4`
  margin-top: 10px;
  margin-bottom: 5px;
`;

const ProfileEmail = styled.span`
  margin-top: 8px;
  width: 100%;
  display: block;
`;

const ProfileGrade = styled.span`
  display: inline-block;
  margin-top: 8px;
  background: #ffffff;
  border-radius: 12px;
  font-size: 0.75rem;
  line-height: 0.75rem;
  padding: 0.5rem 0.75rem;
`;

const StudentAvatar = styled(Avatar)`
  font-family: Nunito;
`;

class StudentProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      profileDataLoaded: false,
      percentDone: 0,
      grade: null,
      retakeAssessment: false,
    };

    this.retakeAssessment = this.retakeAssessment.bind(this);
  }

  componentDidMount() {
    if (!this.props.grade) {
      this.props.getStudentProfile();
    }
    // const { user } = this.props;
    // API.get(`/users/${user.uuid}/student-profile-data`)
    //   .then((response) => {
    //     const { grade, percentDone } = response.data;
    //
    //     this.setState({ grade, percentDone, profileDataLoaded: true });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }

  retakeAssessment(uuid) {
    API.post(`/students/${uuid}/retake-assessment`)
      .then((response) => {
        this.setState({ retakeAssessment: true });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const { user, grade, percentDone, retakeAssessment, loading } = this.props;

    const renderStudentProfileDetails = () => {
      if (!loading) {
        return (
          <>
            <ProfileEmail>{user.email}</ProfileEmail>
            <ProfileGrade>{`${grade}th Grade`}</ProfileGrade>
            <StudentProfileAssessmentSection
              percentDone={percentDone}
              retakeAssessment={this.retakeAssessment}
              user={user}
            />
          </>
        );
      }
      return (
        <SpinnerWrapper>
          <LoadingSpinner animation="border" variant="secondary" />
        </SpinnerWrapper>
      );
    };

    if (retakeAssessment) {
      return <Redirect to="/assessment" />;
    }

    return (
      <>
        <PageWrapper>
          <ProfileHeader>
            <SettingsLink to="/settings">
              <FontAwesomeIcon icon={faCog} />
            </SettingsLink>
            <StudentAvatar
              className="profileAvatar"
              round={true}
              size={80}
              name={`${user.firstName} ${user.lastName}`}
              color="#797878"
            />
            <ProfileName>
              {user.firstName} {user.lastName}
            </ProfileName>
            {renderStudentProfileDetails()}
          </ProfileHeader>
        </PageWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  ...state.student.studentProfile,
});

export default connect(mapStateToProps, { getStudentProfile })(StudentProfile);
