import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import styled from 'styled-components';
import OpportunityCategory from '../../common/Selects/OpportunityCategory';
import OpportunitySubCategory from '../../common/Selects/OpportunitySubCategory';
import OpportunityLocation from '../../common/Selects/OpportunityLocation';
import EmploymentType from '../../common/Selects/EmploymentType';
import States from '../../common/Selects/States';

const SectionTitle = styled.h6`
  color: #000000;
  margin-bottom: 2rem;
`;

const StyledRow = styled(Row)`
  padding: 25px;
`;

export default function LetsGetStarted(props) {
  const {
    values: {
      title,
      clusterId,
      subcategoryId,
      performedAt,
      employmentType,
      location,
      stateId,
    },
    onChange,
  } = props;

  return (
    <>
      <SectionTitle>Let's get started</SectionTitle>
      <Form>
        <Row>
          <Col xs={12}>
            <Form.Group controlId="opportunity_title">
              <Form.Label>Opportunity Title</Form.Label>
              <Form.Control
                onChange={(e) => onChange('title', e.target.value)}
                value={title}
                placeholder="Design Internship"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Form.Group controlId="opportunity_category">
              <Form.Label>Opportunity Category</Form.Label>
              <OpportunityCategory
                onChange={(e) => onChange('clusterId', e.target.value)}
                value={clusterId}
              />
            </Form.Group>
            <Form.Group controlId="type_of_employment">
              <Form.Label>What type of employment is it?</Form.Label>
              <EmploymentType
                onChange={(e) => onChange('employmentType', e.target.value)}
                value={employmentType}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group controlId="opportunity_sub_category">
              <Form.Label>Opportunity Sub Category</Form.Label>
              <OpportunitySubCategory
                categoryId={clusterId}
                onChange={(e) => onChange('subcategoryId', e.target.value)}
                value={subcategoryId}
              />
            </Form.Group>
            <Form.Group controlId="performed_at">
              <Form.Label>Where will this opportunity be performed?</Form.Label>
              <OpportunityLocation
                onChange={(e) => onChange('performedAt', e.target.value)}
                value={performedAt}
              />
            </Form.Group>
            {performedAt === 'ONSITE' ? (
              <Form.Group controlId="location">
                <Row>
                  <Col>
                    <Form.Control
                      value={location}
                      onChange={(e) => onChange('location', e.target.value)}
                    />
                  </Col>
                  <Col>
                    <States
                      value={stateId}
                      onChange={(e) => onChange('stateId', e.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Group>
            ) : null}
          </Col>
        </Row>
      </Form>
    </>
  );
}
