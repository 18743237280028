import {
  COUNSELOR_DASHBOARD_GET,
  COUNSELOR_STUDENTS_GET,
} from '../constants/action-types';
import { API } from '../../settings';

export function getStudents(
  page,
  searchValue = '',
  sortBy = 'firstName',
  sort = 'desc',
  school,
) {
  return function (dispatch, getState) {

    const schoolId = school ? 
      school.id :
      getState().user.userTypeProfile.schoolId;

    const {
      counselor: {
        students: { limit },
      },
    } = getState();

    dispatch({
      type: COUNSELOR_STUDENTS_GET,
      payload: {
        loading: true,
        page: page,
        sortBy: sortBy,
        sort: sort,
        searchValue: searchValue,
      },
    });
    API.get(
      `/students/all/?schoolId=${schoolId}&page=${page}&limit=${limit}&sortBy=${sortBy}&sort=${sort}&search=${searchValue}`
    ).then((res) => {
      dispatch({
        type: COUNSELOR_STUDENTS_GET,
        payload: { loading: false, ...res.data },
      });
    });
  };
}

export function setSearchValue(value = '') {
  return function (dispatch) {
    dispatch({
      type: COUNSELOR_STUDENTS_GET,
      payload: { searchValue: value },
    });
  };
}

export function counselorGetDashboard() {
  return async function (dispatch) {
    dispatch({
      type: COUNSELOR_DASHBOARD_GET,
      payload: { loading: true },
    });
    const { data } = await API.get(`/counselors/dashboard`);
    dispatch({
      type: COUNSELOR_DASHBOARD_GET,
      payload: { loading: false, ...data },
    });
  };
}
