import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const PageWrapper = styled.div`
  padding: 13px 25px 25px;
`;

const SettingsHeader = styled.div`
  text-align: left;
  width: 100%;
  margin-bottom: 1rem;
`;

const BackLink = styled(Link)`
  color: #000000;
  display: inline-block;
  font-size: 14px;
  padding: 12px 0;
  width: 100px;
  &:hover {
    color: #000000;
  }
`;

const Privacy = () => {
  return (
    <PageWrapper>
      <SettingsHeader>
        <BackLink to="/settings">
          <FontAwesomeIcon icon={faChevronLeft} />
        </BackLink>
      </SettingsHeader>
      <h6>PRIVACY POLICY</h6>
      <p>
        This privacy policy discloses the privacy practices for launchnow.org
        and the Launch Now mobile and desktop application This privacy policy
        applies solely to information collected by this web site. It will notify
        you of the following:
      </p>
      <ul>
        <li>
          What personally identifiable information is collected from you through
          the web site, how it is used and with whom it may be shared.
        </li>
        <li>
          What choices are available to you regarding the use of your data.
        </li>
        <li>
          The security procedures in place to protect the misuse of your
          information.
        </li>
        <li>How you can correct any inaccuracies in the information.</li>
      </ul>
      <h6>INFORMATION, USE, COLLECTION, AND SHARING</h6>
      <ul>
        <li>
          We are the sole owners of the information collected on this site. We
          only have access to/ collect information that you voluntarily give us
          via email or other direct contact from you.
        </li>
        <li>We will not sell or rent this information to anyone.</li>
        <li>
          We will use your information to respond to you, regarding the reason
          you contacted us.
        </li>
        <li>
          We will not share your information with any third party outside of our
          organization, other than as necessary to fulfill your request, e.g. to
          ship an order.
        </li>
        <li>
          Unless you ask us not to, we may contact you via email in the future
          to tell you about specials, new products or services, or changes to
          this privacy policy.
        </li>
      </ul>
      <h6>YOUR ACCESS TO CONTROL OVER INFORMATION</h6>
      <p>
        You may opt out of any future contacts from us at any time. You can do
        the following at any time by contacting us via the email address or
        phone number given on our website:
      </p>
      <ul>
        <li>See what data we have about you, if any.</li>
        <li>Change/correct any data we have about you.</li>
        <li>Have us delete any data we have about you.</li>
        <li>Express any concern you have about our use of your data.</li>
      </ul>
      <h6>SECURITY</h6>
      <p>
        We take precautions to protect your information. When you submit
        sensitive information via the website, your information is protected
        both online and offline.
      </p>
      <p>
        Wherever we collect sensitive information (such as credit card data),
        that information is encrypted and transmitted to us in a secure way. You
        can verify this by looking for a closed lock icon at the bottom of your
        web browser or looking for “https” at the beginning of the address of
        the web page.
      </p>
      <p>
        While we use encryption to protect sensitive information transmitted
        online, we also protect your information offline. Only employees who
        need the information to perform a specific job (for example, billing or
        customer service) are granted access to personally identifiable
        information. The computers/servers in which we store personally
        identifiable information are kept in a secure environment.
      </p>
    </PageWrapper>
  );
};

export default Privacy;
