import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import LetsGetStarted from './LetsGetStarted';
import LinearProgressBar from '../../shared/progress/LinearProgressBar';
import OpportunityDetails from './OpportunityDetails';
import ReviewBeforePosting from './ReviewBeforePosting';
import { getOpportunities } from '../../../redux/actions/employer';

import { API } from '../../../settings';
import Loader from '../../common/Loader';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Add20Regular as AddIcon,
  Dismiss20Regular as DismissIcon,
  Edit20Regular as EditIcon,
} from '@fluentui/react-icons';

var Validator = require('jsonschema').Validator;
var v = new Validator();

class EmployerAddNewOpportunity extends Component {
  constructor(props) {
    super(props);

    // TODO: establish 'REMOTE' from settings file
    this.state = {
      modal: false,
      loading: false,
      activeSection: 0,
      sectionsData: [
        {
          title: '',
          clusterId: -1,
          subcategoryId: -1,
          performedAt: 'REMOTE',
          employmentType: -1,
          location: '',
          stateId: 1, // TODO: revisit this hack to set state on load?
        },
        {
          description: '',
        },
      ],
      schemas: [
        {
          type: 'object',
          properties: {
            title: { type: 'string', minLength: 5 },
            categoryId: { type: 'string', minLength: 1 },
            subcategoryId: { type: 'string', minLength: 1 },
            performedAt: { type: 'string', minLength: 2 },
            employmentType: { type: 'string' },
          },
          required: [
            'title',
            'clusterId',
            'subcategoryId',
            'performedAt',
            'employmentType',
            'location',
            'stateId',
          ],
        },
        {
          type: 'object',
          properties: {
            description: { type: 'string', minLength: 10 },
          },
          required: ['description'],
        },
      ],
    };
  }

  componentDidMount() {
    if (this.props.data && Object.keys(this.props.data).length) {
      this.setDataFromProps();
    }
  }

  setDataFromProps = () => {
    let { schemas } = this.state;
    const {
      title,
      clusterId,
      subcategoryId,
      performedAt,
      employmentType,
      location,
      stateId,
      description,
    } = this.props.data;
    schemas = this.updateSchemas(employmentType);
    this.setState({
      sectionsData: [
        {
          title: title,
          clusterId: clusterId.toString(),
          subcategoryId: subcategoryId.toString(),
          performedAt: performedAt,
          employmentType: employmentType,
          location: location,
          stateId: stateId,
        },
        {
          description: description,
        },
      ],
      schemas: schemas,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.data &&
      prevProps.data !== this.props.data &&
      Object.keys(this.props.data).length
    ) {
      this.setDataFromProps();
    }
  }

  toggleModal = () => {
    this.setState((state) => ({ modal: !state.modal }));
  };

  nextSection = () => {
    if (this.state.activeSection < 2) {
      this.setState((state) => ({ activeSection: state.activeSection + 1 }));
    } else {
      this.postOpportunity();
    }
  };

  prevSection = () => {
    this.setState((state) => ({ activeSection: state.activeSection - 1 }));
  };

  onChange = (key, value) => {
    let { sectionsData, activeSection, schemas } = this.state;
    sectionsData[activeSection][key] = value;
    if (key === 'performedAt') {
      schemas = this.updateSchemas(value);
    } else if (key === 'categoryId') {
      sectionsData[activeSection].subcategoryId = -1;
    }
    this.setState({ sectionsData: sectionsData, schemas: schemas });
  };

  updateSchemas = (performedAt) => {
    let { schemas } = this.state;
    if (performedAt === 'REMOTE') {
      schemas[0].properties = {
        title: { type: 'string', minLength: 5 },
        categoryId: { type: 'string', minLength: 1 },
        subcategoryId: { type: 'string', minLength: 1 },
        performedAt: { type: 'string', minLength: 2 },
        employmentType: { type: 'string', minLength: 2 },
      };
      schemas[0].required = [
        'title',
        'clusterId',
        'subcategoryId',
        'performedAt',
        'employmentType',
      ];
    } else if (performedAt === 'ONSITE') {
      schemas[0].required = [
        'title',
        'clusterId',
        'subcategoryId',
        'performedAt',
        'employmentType',
        'location',
        'stateId',
      ];
      schemas[0].properties = {
        title: { type: 'string', minLength: 5 },
        categoryId: { type: 'string', minLength: 1 },
        subcategoryId: { type: 'string', minLength: 1 },
        performedAt: { type: 'string', minLength: 2 },
        employmentType: { type: 'string', minLength: 1 },
        stateId: { type: 'string', minLength: 1 },
        location: { type: 'string', minLength: 2 },
      };
    }
    return schemas;
  };

  postOpportunity = () => {
    const { sectionsData } = this.state;
    const editing = this.props.data;
    this.setState({ loading: true });
    API({
      method: editing ? 'PUT' : 'POST',
      url: editing
        ? `/opportunities/${this.props.match.params.opportunityId}`
        : `/opportunities`,
      data: {
        ...Object.assign({}, ...sectionsData, {
          employerId: this.props.user.userTypeProfile.id,
        }),
      },
    })
      .then((res) => {
        this.setState({
          sectionsData: [
            {
              title: '',
              clusterId: -1,
              subcategoryId: -1,
              performedAt: '',
              employmentType: -1,
              location: '',
              stateId: null,
            },
            {
              description: '',
            },
          ],
          loading: false,
          modal: false,
          activeSection: 0,
        });
        toast.success(`Opportunity ${editing ? 'updated' : 'added'}`);
        this.props.getOpportunities(1, this.props.employerId);
        if (this.props.onFinish) this.props.onFinish();
      })
      .catch((err) => {})
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    const { modal, activeSection, sectionsData, schemas, loading } = this.state;
    const { data } = this.props;
    const sections = [
      <LetsGetStarted
        values={sectionsData[activeSection]}
        onChange={this.onChange}
      />,
      <OpportunityDetails
        values={sectionsData[activeSection]}
        onChange={this.onChange}
      />,
      <ReviewBeforePosting
        values={{ ...Object.assign({}, ...sectionsData) }}
        onChange={this.onChange}
      />,
    ];
    const activeComponent = sections[activeSection];

    let allowNext;
    if (activeSection === 2) {
      allowNext = 1;
    } else {
      allowNext = v.validate(
        sectionsData[activeSection],
        schemas[activeSection]
      ).valid;
    }
    return (
      <React.Fragment>
        {data ? (
          <button onClick={this.toggleModal} className="ln-btn default">
            <EditIcon className="ln-icon" />
            <span>Edit</span>
          </button>
        ) : (
          <button onClick={this.toggleModal} className="ln-btn primary">
            <AddIcon className="ln-icon" />
            <span>Create Opportunity</span>
          </button>
        )}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modal}
          onHide={this.toggleModal}
        >
          <Loader active={loading} dimmer={true} />
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {data ? 'Editing Opportunity' : 'Create Opportunity'}
            </Modal.Title>
            <button
              onClick={this.toggleModal}
              className="ln-btn ln-icon-btn default"
            >
              <DismissIcon className="ln-icon" />
            </button>
            <LinearProgressBar
              progress={(100 / sections.length) * (activeSection + 1)}
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
              }}
            />
          </Modal.Header>
          <Modal.Body style={{ maxHeight: '70vh', overflow: 'auto' }}>
            {activeComponent}
          </Modal.Body>
          <Modal.Footer
            style={{
              justifyContent: 'space-between',
            }}
          >
            {activeSection === 0 ? (
              <div></div>
            ) : (
              <button
                disabled={false}
                onClick={this.prevSection}
                className="ln-btn default"
              >
                Back
              </button>
            )}
            <button
              disabled={!allowNext}
              onClick={this.nextSection}
              className="ln-btn primary"
            >
              {activeSection < 2 ? 'Continue' : `${data ? 'Save' : 'Post'}`}
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, { getOpportunities })(
  withRouter(EmployerAddNewOpportunity)
);
