import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { clearToast } from '../../features/users/userSlice';
import ParentStatus from './DashboardStatCards/ParentStatus';
import DashboardStatCard from './DashboardStatCard';
import PopularSubcategories from './PopularSubcategories';
import StudentsStatus from './DashboardStatCards/StudentsStatus';
import OnboardingStatus from './DashboardStatCards/OnboardingStatus';
import { counselorGetDashboard } from '../../redux/actions/counselor';

const PageHeader = styled.h3`
  margin-bottom: 0;
`;

const SectionTitle = styled.h6`
  font-size: 0.875rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
`;

const AdminDashboard = (props) => {
  const { clearToast, user } = props;
  const { toastMessage, toastStatus } = user;
  const [stats, setStats] = useState({});

  // Todo: abstract this to a commponent
  useEffect(() => {
    if (toastMessage && toastStatus) {
      if (toastStatus === 'success') {
        toast.success(toastMessage);
      } else if (toastStatus === 'info') {
        toast.info(toastMessage);
      }
      clearToast();
    }
  });

  useEffect(() => {
    if (!props.parentsStatus) {
      props.counselorGetDashboard();
    }
    // API.get(`/counselors/dashboard`).then((res) => {
    //   setStats(res.data);
    // });
  }, []);

  let asssessmentsCompleted,
    assessmentsPaused,
    assessmentsNotStarted,
    popularSavedSubcategories,
    popularSubcategoriesByAssessment;

  const {
    assessmentsStatus,
    parentsStatus,
    studentsStatus,
    onboardingByGradeStatus,
    loading,
    rankings,
  } = props;

  if (assessmentsStatus) {
    asssessmentsCompleted = assessmentsStatus.completed.count;
    assessmentsPaused = assessmentsStatus.paused.count;
    assessmentsNotStarted = assessmentsStatus.notStarted.count;
  }

  if (rankings) {
    popularSavedSubcategories = rankings.popularSavedSubcategories;
    popularSubcategoriesByAssessment =
      rankings.popularSubcategoriesByAssessment;
  }

  return (
    <>
      <Container className="p-0 h-100">
        <Row>
          <Col xs={12}>
            <div
              className="horizontal-flex align-center space-between children-mg-r mg-b"
              style={{ height: '40px', marginBottom: '1rem' }}
            >
              <PageHeader>Overview</PageHeader>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: '1rem' }}>
          <Col xs={12}>
            <SectionTitle>Onboarding Students</SectionTitle>
          </Col>
        </Row>
        <Row className="p-0 mg-b">
          <Col lg={9}>
            <OnboardingStatus
              loading={loading}
              onboardingByGradeStatus={onboardingByGradeStatus}
            />
          </Col>
        </Row>
        <Row className="p-0 mg-b" style={{ marginTop: '2rem' }}>
          <Col lg={6}>
            <ParentStatus loading={loading} {...parentsStatus} />
          </Col>
          <Col lg={6}>
            <StudentsStatus loading={loading} {...studentsStatus} />
          </Col>
        </Row>
        <Row style={{ marginTop: '2rem' }}>
          <Col xs={12}>
            <SectionTitle>Assessment Status</SectionTitle>
          </Col>
        </Row>
        <Row className="mg-b">
          <Col lg={3}>
            <DashboardStatCard
              title="Completed"
              raise={true}
              icon={'check'}
              loading={loading}
              value={asssessmentsCompleted}
            />
          </Col>
          <Col lg={3}>
            <DashboardStatCard
              title="Paused"
              raise={true}
              icon={'pause'}
              loading={loading}
              value={assessmentsPaused}
            />
          </Col>
          <Col lg={3}>
            <DashboardStatCard
              title="Not Started"
              raise={true}
              icon={'stop'}
              loading={loading}
              value={assessmentsNotStarted}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '2rem' }}>
          <Col xs={12}>
            <SectionTitle>Popular Subcategories</SectionTitle>
          </Col>
        </Row>
        <Row className="mg-b">
          <Col lg={3}>
            <PopularSubcategories
              title="Saved Subcategories"
              loading={loading}
              subcategories={popularSavedSubcategories}
            />
          </Col>
          <Col lg={3}>
            <PopularSubcategories
              title="By Assessment Results"
              loading={loading}
              subcategories={popularSubcategoriesByAssessment}
            />
          </Col>
        </Row>
        {/* <Row className='mg-b'>
        <Col lg={6}>
          <StatDataCard>
            <div className='horizontal-flex align-center space-between'>
              <h6 style={{ color: '#858585', margin: 0 }}>Total Matches</h6>
              <p style={{ color: '#858585', margin: 0, fontSize: '12px' }}>Based on the outcome of the assesment on the student mobile app</p>
            </div>
          </StatDataCard>
        </Col>
        <Col lg={6}>
          <StatDataCard>
            <div className='horizontal-flex align-center space-between'>
              <h6 style={{ color: '#858585', margin: 0 }}>My Opportunities</h6>
              <p style={{ color: '#858585', margin: 0, fontSize: '12px' }}></p>
            </div>
          </StatDataCard>
        </Col>
      </Row> */}
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  ...state.counselor.dashboard,
});
const mapDispatchToProps = { clearToast, counselorGetDashboard };

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
