import { combineReducers } from 'redux';
import studentsReducer from './students';
import counselorDashboard from './dashboard';

const counselorReducer = combineReducers({
  dashboard: counselorDashboard,
  students: studentsReducer,
});

export default counselorReducer;
