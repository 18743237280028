import { combineReducers } from 'redux';
import userReducer from '../../features/users/userSlice';
import optionsReducer from './selectOptions';
import counselorReducer from './counselor';
import studentReducer from './student';
import employerReducer from './employer';

export default combineReducers({
  user: userReducer,
  counselor: counselorReducer,
  options: optionsReducer,
  student: studentReducer,
  employer: employerReducer,
});
