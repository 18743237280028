import React from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
  min-height: 100vh;
  min-height: -webkit-fill-available;
  background-color: #000000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  opacity: 0.5;
`;

const ButtonPanelWrapper = styled.div`
  position: absolute;
  z-index: 200;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ButtonPanel = styled.div`
  padding: 25px;
  min-height: 10vh;
  background-color: #ffffff;
  max-width: 768px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  opacity: 1;
`;

const SignoutHeader = styled.h6`
  font-family: Nunito;
  font-size: 20px;
  color: #000000;
`;

const SignoutCopy = styled.p`
  color: #000000;
`;

const SignoutButton = styled.button`
  font-weight: normal;
  font-size: 16px;
  padding: 15px;
  border-radius: 10px;
  color: #ffffff;
  background-color: #fe7272;
  border: none;
  float: left;
`;
const SignoutButtonCancel = styled(SignoutButton)`
  background-color: #000000;
  float: right;
  padding-left: 30px;
  padding-right: 30px;
`;

const SignoutPanel = (props) => {
  const { isVisible, togglePanel, logout } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <div>
      <Overlay onClick={togglePanel} />
      <ButtonPanelWrapper>
        <ButtonPanel>
          <SignoutHeader>Sign Out</SignoutHeader>
          <SignoutCopy>
            Are you sure you would like to sign out of LaunchNow?
          </SignoutCopy>
          <SignoutButton onClick={logout}>Sign Out</SignoutButton>
          <SignoutButtonCancel onClick={togglePanel}>
            No. Cancel
          </SignoutButtonCancel>
        </ButtonPanel>
      </ButtonPanelWrapper>
    </div>
  );
};

export default SignoutPanel;
