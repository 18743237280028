import React from 'react';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { API } from '../settings';
import StudentsActivateButton from './StudentsActivateButton';

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const PageWrapper = styled.div`
  min-height: 100vh;
  min-height: -webkit-fill-available;
  height: 100vh;
  position: relative;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const PageLiner = styled.div`
  padding: 25px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  &.noScroll {
    overflow-y: hidden;
  }
`;

const PageWrapperInner = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  text-align: center;
  position: relative;
`;

const SettingsLink = styled(Link)`
  color: #797878;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 24px;

  &:hover {
    color: #797878;
  }
`;

const LoadingSpinner = styled(Spinner)`
  height: 50px;
  width: 50px;
`;

const Intro = styled.p`
  margin-top: 50px;
  text-align: left;
`;

const PageHeader = styled.h6`
  margin-top: 0.5rem;
  font-family: Nunito;
  font-weight: 400;
  font-size: 1rem;
  font-weight: bold;
`;

const ListHeader = styled.h6`
  font-family: Nunito;
  font-weight: 300;
  margin-top: 1rem;
  font-size: 0.75rem;
  text-align: left;
`;

const StudentList = styled.ul`
  margin-bottom: auto;
  list-style-type: none;
  padding: 0;
`;

const StudentListItem = styled.li`
  margin-bottom: auto;
  width: 100%;
  background: #f2f3f5;
  text-align: left;
  padding: 15px;
`;

export default class ActivateStudents extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: '',
      loading: false,
      activationLoading: false,
      activationSent: false,
      students: [],
      parentId: null,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const parentId = localStorage.getItem('parentId');

    this.setState({ loading: true, parentId });

    API.get(`/parents/students-requiring-activation`).then((response) => {
      this.setState({ students: response.data.students, loading: false });
    });
  }

  handleClick(event) {
    event.preventDefault();
    this.setState({ activationLoading: true });

    API.post(`/parents/activate-students`)
      .then((response) => {
        toast.success(
          'Students activated. They will receive an email invite to Launch Now shortly.'
        );

        this.setState({
          activationSent: true,
        });
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(() => {
        this.setState({
          activationLoading: false,
        });
      });
  }

  render() {
    const { loading, students, activationSent } = this.state;

    // TODO: break these out into separate components
    const studentsList = () => {
      const listItems = students.map((student) => (
        <StudentListItem key={student.id.toString()}>
          {student.email}
        </StudentListItem>
      ));
      return <StudentList>{listItems}</StudentList>;
    };

    const displayLoader = () => {
      return (
        <SpinnerWrapper>
          <LoadingSpinner animation="border" variant="secondary" />
        </SpinnerWrapper>
      );
    };
    const displayStudents = () => {
      return (
        <PageWrapperInner>
          <HeaderWrapper>
            <SettingsLink to="/settings">
              <FontAwesomeIcon icon={faCog} />
            </SettingsLink>
            <PageHeader>Confirm Information & Consent</PageHeader>
          </HeaderWrapper>

          <Intro>
            Please review the information below and give consent for your
            children to use the LaunchNow mobile app.
          </Intro>
          <ListHeader>My Children</ListHeader>
          {studentsList()}
          <StudentsActivateButton
            activationLoading={this.state.activationLoading}
            handleClick={this.handleClick}
          />
        </PageWrapperInner>
      );
    };

    const showLoadingOrContents = () => {
      if (loading) {
        return displayLoader();
      }

      return displayStudents();
    };

    if (activationSent) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <PageWrapper>
        <PageLiner>{showLoadingOrContents()}</PageLiner>
      </PageWrapper>
    );
  }
}
