import React, { Component } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import StatusIndicator from '../common/StatusIndicator';
import moment from 'moment';
import { getOpportunities } from '../../redux/actions/employer';
import EmployerAddNewOpportunity from './EmployerAddNewOpportunity/EmployerAddNewOpportunity';
import DropdownButtonLn from '../common/DropdownButtonLn';
import { API } from '../../settings';
import ConfirmLn from '../common/ConfirmLn';
import Loader from '../common/Loader';
import {
  ArrowLeft20Regular as ArrowLeftIcon,
  Clock20Filled as ClockIcon,
  Delete20Regular as DeleteIcon,
  Location24Filled as LocationIcon,
  MoreHorizontal20Regular as MoreIcon,
} from '@fluentui/react-icons';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

const PageTitle = styled.h3`
  margin-bottom: 0;
`;

const Message = styled.div``;

const InfoBlockWithIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ln-icon {
    margin-right: 5px;
  }
`;

const OpportunityWrapper = styled.div`
  background: #ffffff;
  padding: 1.5rem;
  border-radius: 1.25rem;
  box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.05);

  table,
  th,
  td {
    border: none;
  }

  thead {
    th {
      border: none;
    }
  }

  tbody:before {
    content: '@';
    display: block;
    line-height: 0.5rem;
    text-indent: -99999px;
  }

  td {
    font-size: 0.875rem;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }

  td.firstName,
  td.lastName {
    font-size: 1rem;
    font-weight: bold;
    background: none;
  }

  td.grade,
  td.percentDone {
    span {
      background: #fbfbfb;
      display: inline-block;
      padding: 0.2rem 1rem;
      border-radius: 8px;
    }
  }
`;

const MatchFoundBtn = styled.button`
  justify-content: center;
  background-color: #333333;
  color: #fff;

  &.matchFound {
    color: #000;
    background: #ffc34e;
  }
`;

const OpportunityCompletedBtn = styled.button`
  justify-content: center;
  background-color: #ffc34e;
`;

class EmployerOpportunity extends Component {
  state = {
    data: {},
    loading: true,
  };

  componentDidMount() {
    // const {
    //   opportunities,
    //   match: {
    //     params: { opportunityId },
    //   },
    // } = this.props;

    this.getOpportunityDetails();

    // if (opportunities && Array.isArray(opportunities) && opportunities.length) {
    //   for (let i = 0; i < opportunities.length; i++) {
    //     if (opportunities[i].id == opportunityId) {
    //       this.setState({ data: { ...opportunities[i] }, loading: false });
    //       break;
    //     }
    //     if (i === opportunities.length - 1) {
    //       this.getOpportunityDetails();
    //     }
    //   }
    // } else {
    //   this.getOpportunityDetails();
    // }
    // if (this.props.location.state) {
    //   this.setState({ data: { ...this.props.location.state } });
    // } else {
    //   this.getOpportunityDetails();
    // }
  }

  getOpportunityDetails = () => {
    this.setState({ loading: true });
    API.get(`/opportunities/${this.props.match.params.opportunityId}`)
      .then((res) => {
        this.setState({ data: res.data, loading: false });
      })
      .finally(() => this.setState({ loading: false }));
  };

  deleteOpportunity = () => {
    this.setState({ loading: true });
    API.delete(`/opportunities/${this.props.match.params.opportunityId}`).then(
      (res) => {
        this.setState({ data: res.data });
        this.props.getOpportunities(1, this.props.employerId);
        this.props.history.goBack();
      }
    );
  };

  onSelect = (action_type) => {
    switch (action_type) {
      case 'deactivate_post':
        this.patchOpportunity(false, undefined, 'activate_deactivate');
        break;
      case 'activate_post':
        this.patchOpportunity(true, undefined, 'activate_deactivate');
        break;
      default:
        break;
    }
  };

  opportunityUpdated = (data) => {
    this.getOpportunityDetails();
  };

  patchOpportunity = (
    isActive = this.state.data.isActive,
    matchFound = this.state.data.matchFound,
    action_type
  ) => {
    this.setState({ loading: true });
    API.patch(
      `/opportunities/${this.props.match.params.opportunityId}?isActive=${isActive}&matchFound=${matchFound}`
    )
      .then((res) => {
        let { data } = this.state;
        switch (action_type) {
          case 'match_found':
            toast.success('Match found');
            data.matchFound = matchFound;
            break;
          case 'activate_deactivate':
            toast.success(
              isActive ? 'Opportunity activated' : 'Opportunity deactivated'
            );
            data.isActive = isActive;
            break;
          default:
            break;
        }
        this.setState({ data, loading: false });
        this.props.location.state = data;
        this.forceUpdate();
        const { page, searchValue, sortBy, sort } = this.props;
        this.props.getOpportunities(page, this.props.employerId, searchValue, sortBy, sort);
      })
      .finally(() => this.setState({ loading: false }));
  };

  matchFound = (isMatchFound) =>
    this.patchOpportunity(undefined, isMatchFound, 'match_found');

  render() {
    const {
      data: {
        title,
        performedAt,
        employmentType,
        description,
        Cluster,
        Subcategory,
        isActive,
        createdAt,
        location,
        State,
        matchFound,
      },
      loading,
    } = this.state;
    if (Object.keys(this.state.data).length && !loading) {
      return (
        <Container fluid className="p-0 h-100 ">
          <Row>
            <Col xs={12}>
              <div className="horizontal-flex align-center space-between mg-b">
                <div className="horizontal-flex align-center space-between children-mg-r">
                  <button
                    onClick={this.props.history.goBack}
                    className="ln-btn primary"
                  >
                    <ArrowLeftIcon className="ln-icon" />
                    <span>Back</span>
                  </button>
                  <PageTitle>{title}</PageTitle>
                  <StatusIndicator
                    style={{
                      backgroundColor: '#efefef',
                      padding: '0 4px',
                      borderRadius: '8px',
                    }}
                    status={isActive ? 'positive' : 'negative'}
                  />
                  <InfoBlockWithIcon>
                    <ClockIcon className="ln-icon" />
                    {moment(createdAt).fromNow()}
                  </InfoBlockWithIcon>
                  <InfoBlockWithIcon>
                    <LocationIcon className="ln-icon" />
                    {performedAt === 'REMOTE'
                      ? 'Remote'
                      : `${location}, ${State ? State.code : ''}`}
                  </InfoBlockWithIcon>
                </div>
                <div className="horizontal-flex align-center space-between children-mg-l">
                  {!this.props.disableControls && <ConfirmLn
                    renderBtn={
                      <button
                        onClick={this.props.history.goBack}
                        className="ln-btn ln-icon-btn default negative"
                      >
                        <DeleteIcon className="ln-icon" />
                      </button>
                    }
                    title="Delete this opportunity?"
                    onOk={this.deleteOpportunity}
                    position="left"
                  />}
                  {!this.props.disableControls && <EmployerAddNewOpportunity
                    onFinish={this.getOpportunityDetails}
                    opportunityAdded={this.opportunityUpdated}
                    data={this.state.data}
                  />}
                  {!this.props.disableControls && <DropdownButtonLn
                    position="left"
                    onSelect={this.onSelect}
                    options={[
                      {
                        value: isActive ? 'Deactivate Post' : 'Activate Post',
                        action_type: isActive
                          ? 'deactivate_post'
                          : 'activate_post',
                      },
                    ]}
                    renderBtn={
                      <button className="ln-btn ln-icon-btn default">
                        <MoreIcon className="ln-icon" />
                      </button>
                    }
                  ></DropdownButtonLn>}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={9}>
              <OpportunityWrapper>
                <Form.Group controlId="title">
                  <Form.Label>Opportunity Title</Form.Label>
                  <FormControl value={title} />
                </Form.Group>
                <Form.Group controlId="category">
                  <Form.Label>Opportunity Category</Form.Label>
                  <FormControl value={Cluster?.name} readOnly />
                </Form.Group>
                <Form.Group controlId="sub_category">
                  <Form.Label>Opportunity Sub Category</Form.Label>
                  <FormControl value={Subcategory?.name} readOnly />
                </Form.Group>
                <Form.Group controlId="performed_at">
                  <Form.Label>
                    Can this opportunity be performed remotely?
                  </Form.Label>
                  <FormControl
                    value={performedAt === 'remote' ? 'Yes, always' : 'No'}
                    readOnly
                  />
                </Form.Group>
                <Form.Group controlId="type_of_employment">
                  <Form.Label>What type of employment is it?</Form.Label>
                  <FormControl value={employmentType} readOnly />
                </Form.Group>
                <Form.Group controlId="opportunity_description">
                  <Form.Label>Opportunity Description</Form.Label>
                  <FormControl
                    value={description}
                    readOnly
                    as="textarea"
                    rows={8}
                    style={{ resize: 'none' }}
                  />
                </Form.Group>
              </OpportunityWrapper>
            </Col>
            <Col xs={3}>
              {!this.props.disableControls && <ConfirmLn
                renderBtn={
                  <MatchFoundBtn
                    className={`ln-btn ${
                      matchFound ? 'matchFound' : 'primary'
                    } full-width`}
                  >
                    {matchFound ? 'Opportunity Completed' : 'Match Found'}
                  </MatchFoundBtn>
                }
                upperMetaTitle="Mark opportunity as matched"
                title="Are you sure?"
                description={
                  matchFound
                    ? 'This would mean that your candidate completed their contract. This opportunity post will become inactive, but you can reactivate it.'
                    : 'This would mean that you found a student for your opportunity post and they are about to start.'
                }
                onOk={() => this.matchFound(!matchFound)}
                okText="Yes, I'm Sure"
                noText="No, Cancel"
                position="right"
                okBtnStyle={{ backgroundColor: '#3dd598', color: '#fff' }}
              />}
            </Col>
          </Row>
        </Container>
      );
    } else if (loading) {
      return <Loader active />;
    } else {
      return <Loader active />;
    }
  }
}

export default connect(
  (state) => ({
    ...state.employer.opportunities,
  }),
  { getOpportunities }
)(withRouter(EmployerOpportunity));

const FormControl = (props) => {
  return (
    <Form.Control
      style={{ border: 'none', backgroundColor: '#fff' }}
      {...props}
    />
  );
};
