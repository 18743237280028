import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { ToastContainer } from 'react-toastify';
import './App.css';

import EmployerRoutes from './routes/EmployerRoutes';
import CounselorRoutes from './routes/CounselorRoutes';
import StudentRoutes from './routes/StudentRoutes';

import Home from './Home';
import SignIn from './login/SignIn';
import ForgotPassword from './login/ForgotPassword';
import Logout from './login/Logout';
import ActivateAccount from './login/ActivateAccount';
import ResetPassword from './login/ResetPassword';
import ActivateStudentAccount from './login/ActivateStudentAccount';
import ActivateStudents from './components/ActivateStudents';
import AdminDashboard from './components/counselor/AdminDashboard';
import SuperAdminClusters from './components/SuperAdminClusters';
import AllClusters from './components/AllClusters';
import Cluster from './components/Cluster';
import ClusterSubcategories from './components/ClusterSubcategories';
import ClusterQuestions from './components/ClusterQuestions';
import CreateAccount from './CreateAccount';
import Dashboard from './components/Dashboard';
import Favorites from './components/Favorites';
import Profile from './components/Profile';
import Settings from './components/Settings';
import Privacy from './components/Privacy';
import ChangePassword from './components/ChangePassword';
import PasswordUpdate from './components/PasswordUpdate';
import Student from './components/student-view';
// import StudentAnswers from './components/counselor/StudentAnswers';
import StudentAssessment from './components/StudentAssessment';
import StudentAssessmentIntro1 from './components/student/StudentAssessmentIntro1';
import StudentAssessmentIntro2 from './components/student/StudentAssessmentIntro2';
import StudentAssessmentIntro3 from './components/student/StudentAssessmentIntro3';
import Students from './components/counselor/Students';
import StudentTestingDashboard from './components/StudentTestingDashboard';
import SuperAdminDashboard from './components/SuperAdminDashboard';

import Assessment from './components/Assessment';
import ImportClustersData from './components/ImportClustersData';
import CounselorLoggedInLayout from './components/layouts/CounselorLoggedInLayout';
import SuperAdminLoggedInLayout from './components/layouts/SuperAdminLoggedInLayout';
import PrimaryLayout from './components/layouts/PrimaryLayout';
import StudentEmailPreferences from './components/StudentEmailPreferences';
import StudentInfoUpdate from './components/StudentInfoUpdate';
import UploadStudents from './components/counselor/UploadStudents';
import Schools from './components/super-admin/Schools';
import Counselors from './components/super-admin/Counselors';
import ImportSchools from './components/super-admin/ImportSchools';
import ImportEmployers from './components/super-admin/ImportEmployers';
import StudentInterestedIn from './components/student/StudentInterestedIn/StudentInterestedIn';
import SavedOpportunities from './components/student/SavedOpportunities/SavedOpportunities';
import SuperAdminEmployers from './components/super-admin/SuperAdminEmployers';
import SuperAdminEmployer from './components/super-admin/SuperAdminEmployer';
import SuperAdminSchool from './components/super-admin/SuperAdminSchool';
import SuperAdminCounselor from './components/super-admin/SuperAdminCounselor';
import EmployerOpportunity from './components/employer/EmployerOpportunity';

function App(props) {
  const { user } = props;

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/sign-in" render={(props) => <SignIn {...props} />} />
          <Route path="/forgot-password">
            <ForgotPassword />
          </Route>
          <Route path="/reset-forgotten-password">
            <SignIn />
          </Route>
          <Route path="/logout">
            <Logout />
          </Route>
          <Route path="/create-account">
            <CreateAccount />
          </Route>
          <Route
            path="/admin/dashboard"
            render={(props) => (
              <CounselorLoggedInLayout {...props}>
                <AdminDashboard {...props} />
              </CounselorLoggedInLayout>
            )}
          />
          <Route
            path="/admin/students/:uuid"
            render={(props) => (
              <CounselorLoggedInLayout {...props}>
                <Student {...props} />
              </CounselorLoggedInLayout>
            )}
          />
          <Route
            path="/admin/students/:uuid/answers"
            render={(props) => (
              <CounselorLoggedInLayout {...props}>
                <Student {...props} />
              </CounselorLoggedInLayout>
            )}
          />
          <Route
            path="/admin/students"
            render={(props) => (
              <CounselorLoggedInLayout {...props}>
                <Students {...props} />
              </CounselorLoggedInLayout>
            )}
          />
          <Route
            path="/admin/upload-students"
            render={(props) => (
              <CounselorLoggedInLayout {...props}>
                <UploadStudents {...props} />
              </CounselorLoggedInLayout>
            )}
          />
          <Route path="/employer">
            <EmployerRoutes {...props} />
          </Route>
          <Route path="/counselor">
            <CounselorRoutes {...props} />
          </Route>
          <Route path="/student">
            <StudentRoutes {...props} />
          </Route>
          <Route path="/parent">
            <StudentRoutes {...props} />
          </Route>
          <Route
            path="/super-admin/dashboard"
            render={(props) => (
              <SuperAdminLoggedInLayout {...props}>
                <SuperAdminDashboard {...props} />
              </SuperAdminLoggedInLayout>
            )}
          />
          <Route
        path={`/super-admin/opportunities/:opportunityId`}
        render={(props) => (
          <SuperAdminLoggedInLayout {...props}>
            <EmployerOpportunity disableControls {...props} />
          </SuperAdminLoggedInLayout>
        )}
      />
          <Route
            path="/super-admin/schools"
            exact
            render={(props) => (
              <SuperAdminLoggedInLayout {...props}>
                <Schools {...props} />
              </SuperAdminLoggedInLayout>
            )}
          />

          <Route
            path="/super-admin/schools/:id"
            render={(props) => (
              <SuperAdminLoggedInLayout {...props}>
                <SuperAdminSchool {...props} />
              </SuperAdminLoggedInLayout>
            )}
          />
          <Route
            path="/super-admin/counselors"
            exact
            render={(props) => (
              <SuperAdminLoggedInLayout {...props}>
                <Counselors {...props} />
              </SuperAdminLoggedInLayout>
            )}
          />
          <Route
            path="/super-admin/counselors/:id"
            render={(props) => (
              <SuperAdminLoggedInLayout {...props}>
                <SuperAdminCounselor {...props} />
              </SuperAdminLoggedInLayout>
            )}
          />
          <Route
            path={`/super-admin/students/:uuid`}
            render={(props) => (
              <SuperAdminLoggedInLayout {...props}>
                <Student {...props} allowDelete />
              </SuperAdminLoggedInLayout>
            )}
          />
          <Route
            path="/super-admin/employers"
            exact
            render={(props) => (
              <SuperAdminLoggedInLayout {...props}>
                <SuperAdminEmployers {...props} />
              </SuperAdminLoggedInLayout>
            )}
          />
          <Route
            path="/super-admin/employers/:id"
            render={(props) => (
              <SuperAdminLoggedInLayout {...props}>
                <SuperAdminEmployer {...props} />
              </SuperAdminLoggedInLayout>
            )}
          />
          <Route
            path="/super-admin/import-schools"
            render={(props) => (
              <SuperAdminLoggedInLayout {...props}>
                <ImportSchools {...props} />
              </SuperAdminLoggedInLayout>
            )}
          />
          <Route
            path="/super-admin/import-employers"
            render={(props) => (
              <SuperAdminLoggedInLayout {...props}>
                <ImportEmployers {...props} />
              </SuperAdminLoggedInLayout>
            )}
          />
          <Route
            path="/super-admin/assessment"
            render={(props) => (
              <SuperAdminLoggedInLayout {...props}>
                <Assessment {...props} />
              </SuperAdminLoggedInLayout>
            )}
          />
          <Route
            path="/super-admin/clusters"
            render={(props) => (
              <SuperAdminLoggedInLayout {...props}>
                <SuperAdminClusters {...props} />
              </SuperAdminLoggedInLayout>
            )}
          />
          <Route
            path="/super-admin/cluster-questions"
            render={(props) => (
              <SuperAdminLoggedInLayout {...props}>
                <ClusterQuestions {...props} />
              </SuperAdminLoggedInLayout>
            )}
          />
          <Route
            path="/super-admin/import-clusters-data"
            render={(props) => (
              <SuperAdminLoggedInLayout {...props}>
                <ImportClustersData {...props} />
              </SuperAdminLoggedInLayout>
            )}
          />
          <Route
            path="/users/:activationToken/activate"
            render={(props) => (
              <PrimaryLayout {...props}>
                <ActivateAccount {...props} />
              </PrimaryLayout>
            )}
          />
          <Route
            path="/users/:passwordResetToken/reset-password"
            render={(props) => (
              <PrimaryLayout {...props}>
                <ResetPassword {...props} />
              </PrimaryLayout>
            )}
          />
          <Route
            path="/students/:activationToken/activate"
            render={(props) => (
              <PrimaryLayout {...props}>
                <ActivateStudentAccount {...props} />
              </PrimaryLayout>
            )}
          />
          <Route
            path="/dashboard"
            render={(props) => (
              <PrimaryLayout {...props} showNav={true} user={user}>
                <Dashboard {...props} />
              </PrimaryLayout>
            )}
          />
          <Route
            path="/favorites"
            render={(props) => (
              <PrimaryLayout {...props} showNav={true} user={user}>
                <Favorites {...props} user={user} />
              </PrimaryLayout>
            )}
          />
          <Route
            path="/saved"
            render={(props) => (
              <PrimaryLayout {...props} showNav={true} user={user}>
                <SavedOpportunities {...props} user={user} />
              </PrimaryLayout>
            )}
          />
          <Route
            path="/interestedIn"
            render={(props) => (
              <PrimaryLayout {...props} showNav={true} user={user}>
                <StudentInterestedIn {...props} user={user} />
              </PrimaryLayout>
            )}
          />
          <Route
            path="/clusters/:clusterId/subcategories"
            render={(props) => (
              <PrimaryLayout {...props}>
                <ClusterSubcategories {...props} />
              </PrimaryLayout>
            )}
          />
          <Route
            path="/clusters/:clusterId"
            render={(props) => (
              <PrimaryLayout {...props}>
                <Cluster {...props} />
              </PrimaryLayout>
            )}
          />
          <Route
            path="/clusters"
            render={(props) => (
              <PrimaryLayout {...props}>
                <AllClusters {...props} />
              </PrimaryLayout>
            )}
          />
          {/* TODO: remove this route */}
          <Route
            path="/student-testing-dashboard"
            render={(props) => (
              <PrimaryLayout {...props}>
                <StudentTestingDashboard {...props} />
              </PrimaryLayout>
            )}
          />
          <Route
            path="/assessment-intro-1"
            render={(props) => (
              <PrimaryLayout {...props}>
                <StudentAssessmentIntro1 {...props} />
              </PrimaryLayout>
            )}
          />
          <Route
            path="/assessment-intro-2"
            render={(props) => (
              <PrimaryLayout {...props}>
                <StudentAssessmentIntro2 {...props} />
              </PrimaryLayout>
            )}
          />
          <Route
            path="/assessment-intro-3"
            render={(props) => (
              <PrimaryLayout {...props}>
                <StudentAssessmentIntro3 {...props} />
              </PrimaryLayout>
            )}
          />
          <Route
            path="/assessment"
            render={(props) => (
              <PrimaryLayout {...props}>
                <StudentAssessment {...props} />
              </PrimaryLayout>
            )}
          />
          <Route
            path="/assessment-demo"
            render={(props) => (
              <PrimaryLayout {...props}>
                <StudentAssessment {...props} isDemo={true} />
              </PrimaryLayout>
            )}
          />
          <Route
            path="/profile"
            render={(props) => (
              <PrimaryLayout {...props} showNav={true}>
                <Profile {...props} />
              </PrimaryLayout>
            )}
          />
          <Route
            path="/settings"
            render={(props) => (
              <PrimaryLayout {...props}>
                <Settings {...props} />
              </PrimaryLayout>
            )}
          />
          <Route
            path="/privacy"
            render={(props) => (
              <PrimaryLayout {...props}>
                <Privacy {...props} />
              </PrimaryLayout>
            )}
          />
          <Route
            path="/my-info"
            render={(props) => (
              <PrimaryLayout {...props}>
                <StudentInfoUpdate {...props} />
              </PrimaryLayout>
            )}
          />
          <Route
            path="/change-password"
            render={(props) => (
              <PrimaryLayout {...props}>
                <PasswordUpdate {...props} />
              </PrimaryLayout>
            )}
          />
          <Route
            path="/email-preferences"
            render={(props) => (
              <PrimaryLayout {...props}>
                <StudentEmailPreferences {...props} />
              </PrimaryLayout>
            )}
          />
          <Route
            path="/change-password-old"
            render={(props) => (
              <PrimaryLayout {...props}>
                <ChangePassword {...props} />
              </PrimaryLayout>
            )}
          />
          <Route
            path="/activate-students"
            render={(props) => (
              <PrimaryLayout {...props}>
                <ActivateStudents {...props} />
              </PrimaryLayout>
            )}
          />
        </Switch>
      </Router>
    </>
  );
}

const mapStateToProps = (state) => ({ user: state.user });
export default connect(mapStateToProps)(App);
