import dotenv from 'dotenv';
import axios from 'axios';

dotenv.config();

export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
export const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
export const sentryTracesSampleRate = parseFloat(
  process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE
);
export const MAX_WIDTH_PIXELS = 576;
export const algolia = {
  appId: process.env.REACT_APP_ALGOLIA_APP_ID,
  searchApiKey: process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
  indexName: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
};

export const API = axios.create({
  baseURL: apiBaseUrl,
  withCredentials: true,
});

API.CancelToken = axios.CancelToken;
API.isCancel = axios.isCancel;
