import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { clearToast } from '../../features/users/userSlice';
import DashboardStatCard from './DashboardStatCard';
import { employerGetDashboard } from '../../redux/actions/employer';

const PageHeader = styled.h3`
  margin-bottom: 0;
`;

const StatDataCard = styled.div`
  width: 100%;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.02);
  height: min-content;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
`;

const Title = styled.h6`
  font-size: 14px;
`;

const EmployerDashboard = (props) => {
  const {
    clearToast,
    user,
    loading,
    opportunitiesStatus,
    employerGetDashboard,
  } = props;
  const { toastMessage, toastStatus } = user;
  const [stats, setStats] = useState({});
  useEffect(() => {
    if (!Object.keys(opportunitiesStatus).length) {
      employerGetDashboard();
    }
    // API.get(`/employers/${user.userTypeProfile.id}/dashboard`).then((res) => {
    //   setStats(res.data);
    // });
  }, []);

  return (
    <Container fluid className="p-0 h-100">
      <Row>
        <Col xs={12}>
          <div
            className="horizontal-flex align-center space-between children-mg-r mg-b"
            style={{ height: '40px' }}
          >
            <PageHeader>Overview</PageHeader>
          </div>
        </Col>
      </Row>
      <Title>Opportunities</Title>
      <Row className="mg-b">
        <Col lg={3}>
          <DashboardStatCard
            loading={loading}
            number={opportunitiesStatus.totalOpportunities}
            title="Total Opportunities"
            raise={true}
            percent={''}
          />
        </Col>
        <Col lg={3}>
          <DashboardStatCard
            loading={loading}
            number={opportunitiesStatus.totalViews}
            title="Total Views"
            raise={true}
            percent={''}
          />
        </Col>
        <Col lg={3}>
          <DashboardStatCard
            loading={loading}
            number={opportunitiesStatus.totalSaved}
            title="Total Saved"
            raise={true}
            percent={''}
          />
        </Col>
        <Col lg={3}>
          <DashboardStatCard
            loading={loading}
            number={opportunitiesStatus.totalInterested}
            title="Interested In"
            raise={false}
            percent={''}
          />
        </Col>
      </Row>
      {/* <Row className='mg-b'>
        <Col lg={6}>
          <StatDataCard>
            <div className='horizontal-flex align-center space-between'>
              <h6 style={{ color: '#858585', margin: 0 }}>Total Matches</h6>
              <p style={{ color: '#858585', margin: 0, fontSize: '12px' }}>Based on the outcome of the assesment on the student mobile app</p>
            </div>
          </StatDataCard>
        </Col>
        <Col lg={6}>
          <StatDataCard>
            <div className='horizontal-flex align-center space-between'>
              <h6 style={{ color: '#858585', margin: 0 }}>My Opportunities</h6>
              <p style={{ color: '#858585', margin: 0, fontSize: '12px' }}></p>
            </div>
          </StatDataCard>
        </Col>
      </Row> */}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  ...state.employer.dashboard,
});
const mapDispatchToProps = { clearToast, employerGetDashboard };

export default connect(mapStateToProps, mapDispatchToProps)(EmployerDashboard);
