import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ProfileAvatar from '../shared/ProfileAvatar';
import StudentSidebarEmail from './StudentSidebarEmail';
import StudentSidebarParentName from './StudentSidebarParentName';

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 800px;
  width: 250px;
  border-radius: 20px;
  background: #f1f1f1;
`;

const Top = styled.div`
  width: 100%;
  text-align: center;
`;
const Bottom = styled(Top)`
  ${'' /* margin-top: auto; */}
  margin-top: 85px;
  text-align: left;
  padding-bottom: 65px;
`;

const NameWrapper = styled.div`
  margin-top: 1.25rem;
`;

const Name = styled.div`
  color: #000000;
  margin-top: 0.125rem;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.5rem;
`;

const Grade = styled.div`
  margin-top: 0.25rem;
  color: #858585;
  font-size: 0.875rem;
  font-weight: bold;
`;

const StudentSidebar = (props) => {
  const { firstName, lastName, email, grade, parentFirstName, parentLastName } =
    props;

  return (
    <Sidebar>
      <Top>
        <ProfileAvatar {...props} />
        <NameWrapper>
          <Name>{firstName}</Name>
          <Name>{lastName}</Name>
          <Grade>{grade}th Grade</Grade>
        </NameWrapper>
      </Top>
      <Bottom>
        <StudentSidebarEmail email={email} />
        <StudentSidebarParentName
          parentFirstName={parentFirstName}
          parentLastName={parentLastName}
        />
      </Bottom>
    </Sidebar>
  );
};

export default StudentSidebar;

StudentSidebar.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  grade: PropTypes.number.isRequired,
  parentFirstName: PropTypes.string,
  parentLastName: PropTypes.string,
};
