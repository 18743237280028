import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLongArrowAltRight,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';

const Overlay = styled.div`
  height: 100vh;
  background-color: #000000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
`;

const ContentWrapper = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 101;
  background: #000000;
  color: purple;
  text-align: center;
`;

const CongratsHeader = styled.h2`
  color: #ffffff;
`;
const CongratsCopy = styled.p`
  color: #afafaf;
  padding: 10px 50px 50px;
`;

const RoundedLink = styled.a`
  display: block;
  background-color: #ffc34e;
  ${'' /* position: absolute; */}
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 200;
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
  padding: 80px 0 0;
`;

const LinkArrow = styled.span`
  display: block;
  padding: 0 0 20px;
`;
const LinkCopy = styled.span`
  display: block;
  padding: 0 0 25px;
`;

const StudentAssessmentInterstitial = (props) => {
  let congratsCopyLine1, congratsCopyLine2, linkIcon, linkCopy;

  const {
    closeInterstitial,
    currentQuestionSectionIndex,
    isVisible,
    isFinalQuestion,
    nextQuestionSectionIndex,
    sectionsCount,
  } = props;

  if (!isVisible) {
    return null;
  }

  if (isFinalQuestion) {
    congratsCopyLine1 = `You're done!`;
    linkIcon = <FontAwesomeIcon icon={faCheck} size="3x" />;
    linkCopy = 'Back to Dashboard';
  } else {
    congratsCopyLine1 = `You completed step ${
      currentQuestionSectionIndex + 1
    } of ${sectionsCount}.`;
    congratsCopyLine2 = `Just ${
      sectionsCount - nextQuestionSectionIndex
    } more to go and you're done.`;
    linkIcon = <FontAwesomeIcon icon={faLongArrowAltRight} size="3x" />;
    linkCopy = 'Next Step';
  }

  return (
    <div>
      <Overlay />
      <ContentWrapper>
        <CongratsHeader>Awesome!</CongratsHeader>
        <CongratsCopy>
          {congratsCopyLine1}
          <br />
          {congratsCopyLine2}
        </CongratsCopy>
        <RoundedLink onClick={closeInterstitial}>
          <LinkArrow>{linkIcon}</LinkArrow>
          <LinkCopy>{linkCopy}</LinkCopy>
        </RoundedLink>
      </ContentWrapper>
    </div>
  );
};

export default StudentAssessmentInterstitial;
