import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { logout } from '../features/users/userSlice';

const Logout = (props) => {
  const { logout, user } = props;

  if (user && user.isLoggedIn) {
    logout(user.id);
  }

  return <Redirect to="/" />;
};

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = { logout };

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
