import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faUserCircle,
  faKey,
  faEnvelope,
  faFileAlt,
  faShieldAlt,
  faLifeRing,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import SignoutPanel from './SignoutPanel';

const PageWrapper = styled.div`
  min-height: 100vh;
  max-height: 100vh;
  min-height: -webkit-fill-available;
  max-height: -webkit-fill-available;
  padding: 25px;
  position: relative;
`;

const SettingsHeader = styled.div`
  text-align: center;
  position: relative;
  h6 {
    font-size: 1rem;
  }
`;

const BackLink = styled(Link)`
  color: #000000;
  display: inline-block;
  position: absolute;
  top: -3px;
  left: 0;
  font-size: 1rem;
  &:hover {
    color: #000000;
  }
`;

const SettingsSectionTitle = styled.h6`
  font-family: Nunito;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 35px;
  padding-bottom: 5px;
`;

const SettingsLink = styled(Link)`
  color: #000000;
  font-size: 1rem;
  display: block;
  margin-top: 20px;
  &:hover {
    color: #000000;
    text-decoration: none;
  }
`;

const SignOutLink = styled(SettingsLink)`
  color: #fe7272;
  &:hover {
    color: #fe7272;
  }
`;

const StudentSettings = (props) => {
  const [isVisible, setVisibility] = useState(false);
  const history = useHistory();

  const togglePanel = (event) => {
    event.preventDefault();
    setVisibility(!isVisible);
  };

  const logout = (event) => {
    event.preventDefault();
    console.log('signing out');
    history.push('/logout');
  };

  return (
    <>
      <PageWrapper>
        <SettingsHeader>
          <BackLink to="/profile">
            <FontAwesomeIcon icon={faChevronLeft} />
          </BackLink>
          <h6>Settings</h6>
        </SettingsHeader>
        <SettingsSectionTitle>Account</SettingsSectionTitle>
        <SettingsLink to="/my-info">
          <FontAwesomeIcon icon={faUserCircle} />
          &nbsp; My Info
        </SettingsLink>
        <SettingsLink to="/change-password">
          <FontAwesomeIcon icon={faKey} />
          &nbsp; Change Password
        </SettingsLink>
        {/* <SettingsLink to="/email-preferences">
          <FontAwesomeIcon icon={faEnvelope} />
          &nbsp; Email Notifications
        </SettingsLink> */}
        <SettingsSectionTitle>&nbsp; Support</SettingsSectionTitle>
        <SettingsLink to="/terms">
          <FontAwesomeIcon icon={faFileAlt} />
          &nbsp; Terms of Use (coming soon)
        </SettingsLink>
        <SettingsLink to="/privacy">
          <FontAwesomeIcon icon={faShieldAlt} />
          &nbsp; Privacy Policy
        </SettingsLink>
        <SettingsLink to="/help">
          <FontAwesomeIcon icon={faLifeRing} />
          &nbsp; FAQ (coming soon)
        </SettingsLink>
        <SignOutLink to="/logout" onClick={togglePanel}>
          <FontAwesomeIcon icon={faSignOutAlt} rotation={180} />
          &nbsp; Sign Out
        </SignOutLink>
      </PageWrapper>
      <SignoutPanel
        isVisible={isVisible}
        togglePanel={togglePanel}
        logout={logout}
      />
    </>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(StudentSettings);
